import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from "gatsby-image";

import Employee from './component.employee';

const Distributor = ({ distributor }) => {
    
    const [saleTab, setSaleTab] = useState(false);
    const [infoTab, setInfoTab] = useState(false);
    const { title, ACFDealerBlockFields: details } = distributor;
    return (
        <article className="c-distributor">
            

            <div className="c-distributor__content">
                <header className="c-distributor__header">
                    {details.country &&
                        <h1 className="c-distributor__country">{details.country}</h1>
                    }
                    {details.language &&
                        <span className="c-distributor__language">{details.language}</span>
                    }
                    {title &&
                        <h2 className="c-distributor__title" dangerouslySetInnerHTML={{ __html: title }}></h2>
                    }
                </header>

                <div>
                    {details.address &&
                        <div className="c-distributor__address">
                            <span className="c-distributor__address-details" dangerouslySetInnerHTML={{ __html: details.address }}></span>
                        </div>
                    }
                    {(details.email || details.website || details.phone) &&
                        <div className="c-distributor__contact">
                            <ul className="c-distributor__contact-list">
                                {details.email &&
                                    <li className="c-distributor__contact-item c-distributor__contact-item__email">
                                        <a href={`mailto:${details.email}`}>{details.email}</a>
                                    </li>
                                }
                                {details.website &&
                                    <li className="c-distributor__contact-item c-distributor__contact-item__site">
                                        <a href={details.website}>{details.website}</a>
                                    </li>
                                }
                                {details.phone &&
                                    <li className="c-distributor__contact-item c-distributor__contact-item__phone">
                                        <a href={`tel:${details.phone}`}>{details.phone}</a>
                                    </li>
                                }
                            </ul>
                        </div>
                    }
                </div>
            </div>
        </article>
    );
};

export default Distributor;
